import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import VueElementLoading from 'vue-element-loading'
import SnackBar from './components/Common/snackBar'
import ServerError from './components/Common/500'
import ImageLoader from './components/Common/imageLoader'
import PageLoader from './components/Common/pageLoader'

import OwlCarousel from 'vue-owl-carousel'
Vue.component('OwlCarousel', OwlCarousel)

import ImageMagnifier from 'vue-image-magnifier'
Vue.use(ImageMagnifier)

import "viewerjs/dist/viewer.css";
import viewer from "v-viewer";
Vue.use(viewer);

import OtpInput from "@bachdgvn/vue-otp-input";
 
Vue.component("v-otp-input", OtpInput);

Vue.component('ServerError', ServerError)
Vue.component('SnackBar', SnackBar)
Vue.component('ImageLoader', ImageLoader)
Vue.component('PageLoader', PageLoader)
Vue.component('VueElementLoading', VueElementLoading)

Vue.config.productionTip = false
window.axios = require('axios')
axios.defaults.baseURL = 'https://wtiadmin.in';
Vue.prototype.baseURL = "https://wtiadmin.in"
Vue.prototype.mediaURL = "https://wtiadmin.in/i/"


// axios.defaults.baseURL = 'http://192.168.51.71:5000';
// Vue.prototype.baseURL = "http://192.168.51.71:5000"
Vue.prototype.appColor = "#005f32"
// Vue.prototype.mediaURL = "https://192.168.50.87:5000/i/"

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
