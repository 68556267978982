import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
import axios from "axios";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    level: 0,
    isLoggedIn: !!localStorage.getItem("token"),
    userType: localStorage.getItem("userType"),
    // userType: 'Client',
    cartItems: 0,
    wishlistItems: 0,
    userData: {},
    userDetailed: {},
    loginAlternate: false,
    fullPath: null,
    profileStatus: null,
    profileCondition: null,
    searchData: null,
    rvarib: null,
  },
  mutations: {
    loginUser(state, item) {
      state.isLoggedIn = item;
      localStorage.setItem("token", item);
      localStorage.removeItem("GuestID");

      if (
        localStorage.getItem("routeKey") == "/productCart" &&
        state.isLoggedIn
      ) {
        router.push(localStorage.getItem("routeKey"));
      } else if (state.userType == "customer") {
        // router.push("/Shopping");
        router.push(localStorage.getItem("routeKey"));
      }
    },

    LOGIN_ALTERNATE(state, item) {
      state.loginAlternate = item;
    },

    lastPage_fullPath(state, item) {
      // console.log(state,item,"fghj")
      state.fullPath = item;
    },

    logoutUser(state) {
      // axios({
      //   method: "post",
      //   url: "user/logout",
      //   headers: {
      //     token: localStorage.getItem("token"),
      //   },
      // }).then((response) => {
      //   if (response.data) {
      state.userType = "customer";
      state.userData = {};
      state.coupen = {};
      localStorage.setItem("userType", "customer");
      state.isLoggedIn = false;
      state.cartItems = 0;
      state.wishlistItems = 0;
      localStorage.removeItem("token");
      localStorage.removeItem("categoryId");
      localStorage.removeItem("category");
      localStorage.removeItem("profStat");
      localStorage.removeItem("GuestID");

      //   }
      // });

      axios({
        method: "GET",
        url: "guest/uniqueid",
      })
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem("GuestID", response.data.guestId);
          }
        })
        .catch((err) => {
          this.$store.commit("appLoading", false);
          console.log(err);
        });


      
        router.push("/login");
    },
    rvar(state, item) {
      state.rvarib = item;
    },
    changeCart(state, item) {
      state.cartItems = item;
    },
    changeWishlist(state, item) {
      state.wishlistItems = item;
    },
    userData(state, item) {
      state.userData = item;
    },
    userDetailed(state, item) {
      state.userDetailed = item;
      localStorage.setItem("profStat", item.profileStatus);
    },

    changePicture(state, item) {
      state.userData.image = item;
    },
    changeCoverPicture(state, item) {
      state.userData.seller.coverimage = item;
    },
    userType(state, item) {
      state.userType = item;
      localStorage.setItem("userType", item);
    },
    registrationStage(state, item) {
      state.level = item;
    },
    profilestatus(state, item) {
      state.profileStatus = item;
    },

    sessionOut(state) {
      localStorage.removeItem("token");
      state.isLoggedIn = false;
      router.push("/login");
    },
  },
  actions: {},
});
