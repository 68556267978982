<template>
  <div>
    <!-- <v-card>
        
      </v-card> -->
    <v-app-bar
      v-if="$vuetify.breakpoint.name !== 'xs'"
      class="hidden-md-and-down headGrad"
      :color="'#005f32'"
      :height="dispFlag?'140px':'90px'"
      elevation="6"
      app
      dark
      hide-on-scroll
      absolute
    >
      <v-card width="100%" flat tile color="transparent">
        <v-layout wrap style="color: #272727" v-if="dispFlag">
          <v-flex xs12 align-self-center>
            <v-card tile dark height="40px">
              <v-layout wrap justify-center v-if="!dispFlag">
                <v-flex lg12 align-self-center text-center pt-2>
                  <span style="font-size:12px !important;" v-html="announcement"></span>
                </v-flex>
              </v-layout>

              <v-layout wrap justify-end fill-height v-else>
                <v-flex lg9></v-flex><v-spacer></v-spacer>
                <v-flex lg1 align-self-center>
                  <v-layout wrap justify-end pr-4 pr-lg-5 pr-xl-0>
                    <v-flex
                      align-self-center
                      v-for="(item, i) in icons"
                      :key="i"
                    >
                      <a :href="item.route">
                        <v-icon small color="#82827E">{{
                          item.name
                        }}</v-icon></a
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex lg1></v-flex>
                <v-spacer></v-spacer>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap style="color: #272727;" v-else>
          <v-flex xs12 align-self-start>
            <v-card tile dark height="17px">
              <v-layout wrap justify-center v-if="!dispFlag">
                <v-flex lg12 align-self-center text-center>
                  <span style="font-size:12px !important;" v-html="announcement"></span>
                </v-flex>
              </v-layout>

              <!-- <v-layout wrap justify-end fill-height v-else>
                <v-flex lg9></v-flex><v-spacer></v-spacer>
                <v-flex lg1 align-self-center>
                  <v-layout wrap justify-end pr-4 pr-lg-5 pr-xl-0>
                    <v-flex
                      align-self-center
                      v-for="(item, i) in icons"
                      :key="i"
                    >
                      <a :href="item.route">
                        <v-icon small color="#82827E">{{
                          item.name
                        }}</v-icon></a
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex lg1></v-flex>
                <v-spacer></v-spacer>
              </v-layout> -->
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center fill-height px-7>
          <v-flex xs12 lg8>
            <v-layout wrap justify-center fill-height>
              <v-flex align-self-center sm6 md6 lg2>
                <v-layout wrap justify-start>
                  <v-flex xs12 align-self-start text-left>
                    <!-- <router-link class="routerlink" to="https://www.wti.org.in/"> -->
                    <a href="https://www.wti.org.in/">
                      <v-img
                        contain
                        :height="dispFlag?'80px':'50px'"
                        :src="require('./../../../public/wtilogo.png')"
                      ></v-img>
                    </a>
                    <!-- </router-link> -->
                  </v-flex>
                  <!-- <v-flex xs2 sm1 md10 lg10 align-self-center text-left>
                  <div>
                    <router-link class="routerlink" :to="(name = '/')">
                      <span :class="transAppBar ? 'maintitlehid' : 'maintitle'">
                        Speleological Association of India
                      </span>
                    </router-link>
                  </div>
                </v-flex> -->
                  <!-- <v-flex xs12 class="Subt" align-self-center text-left>
                  A technology innovation challenge to #savethestripes
                </v-flex> -->
                </v-layout>
              </v-flex>

              <v-flex align-self-center xs8 sm6 md6 lg10>
                <v-layout wrap justify-center fill-height>
                  <v-flex sm12 md12 lg12 align-self-center text-center v-if="dispFlag">
                    <v-layout wrap justify-center fill-height>
                      <v-flex
                        v-for="(item, i) in menus"
                        :key="i"
                        align-self-center
                        text-center
                      >
                        <a :href="item.route" v-if="item.name !== 'Account'">
                          <span
                            style="letter-spacing: 1.5px"
                            :class="
                              $vuetify.breakpoint.name == 'md'
                                ? 'popsemibold smallm'
                                : 'popsemibold smallm'
                            "
                          >
                            <span :class="transAppBar ? 'hid' : 'hov'">
                              {{ item.name }}</span
                            >
                          </span>
                        </a>
                        <span v-else>
                          <AccountMenu />
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex sm12 md12 lg10 align-self-center text-center v-else>
                    <v-layout wrap justify-start fill-height>
                      <v-flex
                        v-for="(item, i) in menus"
                        :key="i"
                        align-self-center
                        text-center
                        lg2
                      >
                        <a :href="item.route" v-if="item.name !== 'Account'">
                          <span
                            style="letter-spacing: 1.5px;"
                            :class="
                              $vuetify.breakpoint.name == 'md'
                                ? 'popsemibold smallm text-uppercase'
                                : 'popsemibold smallm text-uppercase'
                            "
                          >
                            <span :class="transAppBar ? 'hid' : 'hov'">
                              {{ item.name }}</span
                            >
                          </span>
                        </a>
                        <span v-else>
                          <AccountMenu />
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex text-center lg1 pl-6 v-if="dispFlag">
            <v-card
              flat
              tile
              height="100px"
              color="#ecb338"
              class="boxx"
              style="border-bottom: 3.5px solid #60511e !important"
            >
              <v-layout wrap justify-center fill-height>
                <v-flex align-self-center xs12 py-5>
                  <a href="https://donation.wti.org.in/">
                    <span class="popregular donatehov">DONATE</span></a
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex text-center lg1 pr-12 class="">
            <v-card
              flat
              tile
              :height="dispFlag?'100px':'76px'"
              color="#f7c644"
              style="border-bottom: 3.5px solid #60511e !important"
            >
              <v-layout wrap justify-center fill-height>
                <v-flex align-self-center xs12 py-5>
                  <v-btn :ripple="false" plain to="/productCart">
                    <v-badge
                      overlap
                      color="#60511e"
                      small
                      offset-x="8"
                      offset-y="4"
                      label="ko"
                      :content="appCart ? appCart : '0'"
                    >
                      <v-icon class="donatehov">mdi-cart</v-icon>
                    </v-badge>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>

          <!-- <v-flex lg1 text-center align-self-center>
            <AccountMenu />
          </v-flex> -->
          <!-- <v-flex xs12 v-if="$route.name != 'home'">
          <v-divider
            v-if="transAppBar"
            style="background-color: black"
          ></v-divider>
        </v-flex> -->
        </v-layout>
      </v-card>
    </v-app-bar>
  </div>
</template>
<script>
import AccountMenu from "./accountMenu";
export default {
  props: ["announcement"],
  components: {
    AccountMenu,
  },
  data() {
    return {
      icons: [
        {
          name: "mdi-facebook",
          route:
            "https://www.facebook.com/pages/Wildlife-Trust-of-India/112289815480421?fref=ts",
        },
        {
          name: "mdi-linkedin",
          route: "https://in.linkedin.com/company/wildlife-trust-of-india",
        },
        {
          name: "mdi-twitter",
          route:
            "https://www.wti.org.in/job/programme-officer-emergency-relief-network/",
        },
        {
          name: "mdi-youtube",
          route: "https://www.youtube.com/user/wildlifetrustofindia",
        },
        {
          name: "mdi-instagram",
          route: "https://www.instagram.com/wildlifetrustofindia/",
        },
      ],
      sideNav: true,
      transAppBar: true,
      whiteAppBar: false,
      menu1: false,
      userNavItems: [
        { name: "ABOUT US", route: "https://www.wti.org.in/" },
        { name: "OUR WORK", route: "https://www.wti.org.in/" },
        { name: "WHY US", route: "https://www.wti.org.in/" },
        { name: "RESOURCE CENTRE", route: "https://www.wti.org.in/" },
        // { name: "SHOP", route: "https://www.jobs.wti.org.in/Shopping" },
        { name: "CONTACT US", route: "https://www.wti.org.in/" },
        { name: "JOBS", route: "/" },
        // { name: "VOLUNTEER", route: "/volunteer" },
      ],
      // appCart:null,
      appLogin: null,
      curPath: null,
      curPathname: null,
      dispFlag: true,
      curlogin: null,
    };
  },
  
  created() {
    this.curPath = localStorage.getItem("routeKey");
    this.curPathname = localStorage.getItem("routeName");
    this.curlogin = localStorage.getItem("routeNamelogin");
    // console.log("Hiii0000")
    this.appLoginC();
    // this.appCartC()
    this.recall();
  },
  computed: {
    menus() {
      return this.userNavItems;
    },
    appCart() {
      return this.$store.state.cartItems;
    },
    curLogg() {
      this.checkagain()
      return localStorage.getItem("routeNamelogin");
    },
    curPathnamed() {
      console.log("ghhj",this.$store.state.rvarib)
      return this.$store.state.rvarib
    }
  },
  methods: {
    checkagain() {
      if (
        this.curLogg == "login"
      ) {
        this.dispFlag = false;
        this.userNavItems = [
          { name: "Home", route: "https://www.jobs.wti.org.in/Shopping" },
          { name: "Catalogue", route: "https://www.jobs.wti.org.in/Shopping" },
          { name: "Account", route: "https://www.jobs.wti.org.in/login" },
        ];
      } else {
        this.dispFlag = true;
      }
    },
    recall() {
      if (
        this.curPathname == "ShopHome" ||
        this.curPathname == "login" ||
        this.curPathname == "Products" ||
        this.curPathname == "Product" ||
        this.curPathname == "ProductsCart" ||
        this.curPathname == "profile" ||
        this.curPathname == "shipDetails" ||
        this.curPathname == "ProductsDetails" ||
        this.curlogin == "login" ||
        this.curPathname == "orders" ||
        this.curPathname == "PaywaitScreenSuccess" ||
        this.curPathname == "PaywaitScreenFailure" ||
        this.curPathname == "PaywaitScreen" ||
        this.curPathname == "Feedback" ||
        this.curPathname == "AllVisitors" ||
        this.curPathname == "AllReviews"
      ) {
        this.dispFlag = false;
        this.userNavItems = [
          { name: "Home", route: "https://www.jobs.wti.org.in/Shopping" },
          { name: "Catalogue", route: "https://www.jobs.wti.org.in/Shopping" },
          { name: "Account", route: "https://www.jobs.wti.org.in/login" },
        ];
      } else {
        this.dispFlag = true;
      }
    },
    appLoginC() {
      // console.log("Created")
      this.appLogin = this.$store.state.isLoggedIn;
      return this.appLogin;
    },

    appCartC() {
      this.appCart = this.$store.state.cartItems;
      return this.appCart;
    },
  },
};
</script>
<style>
@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
.headGrad {
  /* background: transparent; */
  background-color: rgba(0, 95, 50, 0.85) !important;

  /* linear-gradient(104deg, #000000bb 0%, #7e7e7e2a 100%, transparent 100%); */
}
.hov {
  color: #ffffff !important;
}
.hid {
  color: #ffffff !important;
}
.hov:hover {
  color: #e27826 !important;
  /* border-top: 2px solid #097392; */
  border-bottom: 3.5px solid #90ba92;
  padding-bottom: 38px;
}
.hid:hover {
  color: #e27826 !important;
  /* border-top: 2px solid #097392; */
  border-bottom: 3.5px solid #90ba92;
  padding-bottom: 38px;
}
.boxx {
  background-color: #ecb338;
}
.donatehov {
  color: #60511e !important;
  cursor: pointer;
}
.donatehov:hover {
  color: #e27826 !important;
  cursor: pointer;
}
</style>
