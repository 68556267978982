<template>
  <div>
    <v-layout wrap justify-end>
      <!-- <v-flex xs6 text-right v-if="!appLogin">
        <v-btn
          :color="appColor"
          text
          @click="userRegister"
          :ripple="false"
          depressed
          class="text-none rectangle"
        >
          <span class="">Sign in</span>
        </v-btn>
      </v-flex>
      <v-flex xs6 text-left v-if="!appLogin">
        <v-btn
          @click="userRegister"
          dark
          :ripple="false"
          :color="appColor"
          class="text-none rectangle"
        >
          <span>Signup</span>
        </v-btn>
      </v-flex> -->
      <v-flex align-self-center xs4 sm4 md4 lg12 xl12>
        <v-menu
          tile
          transition="slide-y-transition"
          :nudge-width="100"
          bottom
          offset-y
          max-width="180px"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-layout
              style="cursor: pointer"
              wrap
              justify-center
              v-bind="attrs"
              v-on="on"
            >
              <!-- <v-flex xs12>
                <v-icon color="#ffffff" size="25">
                  mdi-account-circle-outline
                </v-icon>
              </v-flex> -->
              <v-flex xs12 style="line-height: 0.5" pt-0>
                <span
                  style="letter-spacing: 1.5px"
                  :class="
                    $vuetify.breakpoint.name == 'md'
                      ? 'popsemibold smallm'
                      : 'popsemibold smallm'
                  "
                >
                  ACCOUNT
                </span>
              </v-flex>
            </v-layout>
          </template>
          <v-card tile flat color="#ffffff">
            <v-layout wrap justify-center>
              <v-flex pt-4 xs12>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 16px;
                    color: #000000;
                  "
                >
                  Welcome
                </span>
              </v-flex>
              <v-flex xs12>
                <span
                  v-if="!appLogin"
                  style="
                    font-family: poppinsmedium;
                    font-size: 12px;
                    color: #1e1b1b;
                  "
                >
                  Sign up as customer
                </span>
              </v-flex>
              <v-flex pt-2 xs12>
                <v-icon color="#CCB4B4" size="30">
                  mdi-account-circle-outline
                </v-icon>
              </v-flex>
              <v-flex xs12>
                <router-link to="/profile">
                  <span
                    v-if="appLogin"
                    style="
                      font-family: poppinsmedium;
                      font-size: 13px;
                      color: #1e1b1b;
                    "
                  >
                    <span v-if="appUser.firstname">
                      {{ appUser.firstname }}&nbsp;
                      {{ appUser.lastname }}
                    </span>
                    <span v-else> My Profile </span>
                  </span>
                </router-link>
              </v-flex>

              <v-flex xs12 py-2>
                <v-layout wrap justify-center>
                  <v-flex xs12 pt-2 v-if="appLogin">
                    <router-link to="/Shopping/orders">
                      <span
                        style="
                          font-family: poppinsregular;
                          font-size: 13px;
                          color: #717171;
                        "
                      >
                        My Orders
                      </span>
                    </router-link>
                  </v-flex>
                  <v-flex xs12 v-if="appLogin">
                    <router-link to="/productCart">
                      <span
                        style="
                          font-family: poppinsregular;
                          font-size: 13px;
                          color: #717171;
                        "
                      >
                        My Cart
                      </span>
                    </router-link>
                  </v-flex>
                  <!-- <v-flex xs12>
                    <router-link to="/Profile/SavedAddress">
                      <span
                        style="
                          font-family: poppinsregular;
                          font-size: 13px;
                          color: #717171;
                        "
                      >
                        Saved Address
                      </span>
                    </router-link>
                  </v-flex> -->
                </v-layout>
              </v-flex>
              <v-flex xs12 py-4>
                <v-btn
                  v-if="!appLogin"
                  color="#0000001f"
                  outlined
                  @click="userRegister"
                  tile
                  small
                  :ripple="false"
                >
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 14px;
                      color: #E27A26;
                    "
                  >
                    LOGIN/SIGNUP
                  </span>
                </v-btn>
                <v-btn
                  v-if="appLogin"
                  color="#0000001f"
                  outlined
                  tile
                  small
                  :ripple="false"
                  @click="appLogout"
                >
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 14px;
                      color: #E27A26;
                    "
                  >
                    Logout
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-menu>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {},
    };
  },
  computed: {
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
    appCart() {
      if (this.$store.state.cartItems == undefined) {
        return 0;
      } else {
        return this.$store.state.cartItems;
      }
    },
    appWishlist() {
      if (this.$store.state.wishlistItems == undefined) {
        return 0;
      } else {
        return this.$store.state.wishlistItems;
      }
    },
    appUser() {
      // console.log("dadda",this.$store.state.userData)
      return this.$store.state.userData;
    },
    appType() {
      return this.$store.state.userType;
    },
  },

  methods: {
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
    sellerRegister() {
      localStorage.setItem("regType", "Seller");
      this.$router.push("/Login");
    },
    consultantRegister() {
      localStorage.setItem("regType", "Consultant");
      this.$router.push("/Login");
    },
    userRegister() {
      localStorage.setItem("regType", "Client");
      this.$router.push("/Login");
    },
  },
};
</script>
<style scoped>
.rectangle {
  border-radius: 17px;
}
</style>
