<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-app id="app" v-scroll="onScroll">
      <v-snackbar
        v-model="showSnackBar"
        color="#005f32"
        right
        :timeout="timeout"
      >
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-divider></v-divider>
      <AppHeader0 v-if="!transAppBar" :announcement="announcement" />
      <AppHeader />
      <Default v-if="transAppBar" />

      <router-view
        :style="
          $vuetify.breakpoint.name == 'md'
            ? 'padding-top:50px'
            : $vuetify.breakpoint.name == 'xs'
            ? 'padding-top:99px'
            : $vuetify.breakpoint.name == 'sm'
            ? 'padding-top:69px'
            : ''
        "
        style="min-height: 90vh"
        :key="$route.fullPath"
        v-bind:storage="sideNav"
      />
      <AppFooter />
    </v-app>
    <!-- <AppFooter v-if="$route.name != 'Login'" v-bind:storage="styloopData" /> -->
  </div>
</template>
<script>
import AppHeader0 from "./components/Common/appHeader0Edited";
import AppHeader from "./components/Common/appHeader";
import Default from "./components/Common/DefaultEdited";
// import AppHeader2 from "./components/Common/appHeader2";
// import AppHeader3 from "./components/Common/appHeader3";
import AppFooter from "./components/Common/appFooter";
import axios from "axios";

import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";

export default {
  components: {
    AppHeader0,
    AppHeader,
    Default,
    // AppHeader2,
    // AppHeader3,
    AppFooter,
  },
  data() {
    return {
      sideNav: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      msg: null,
      timeout: 5000,
      userInfo: null,
      queryPopUpDialog: false,
      transAppBar: false,
      announcement: null,
      isGuest: null,
    };
  },
  created() {
    this.isGuest = localStorage.getItem("GuestID");
    // alert("j")
  },
  beforeMount() {
    if (
      localStorage.getItem("userType") == "customer" &&
      localStorage.getItem("token")
    ) {
      this.getUser();
    } else if (!this.isGuest) {
      this.getGuest();
    } else if (this.isGuest) {
      this.guestInfo();
    }
    this.getNotice();
  },
  methods: {
    getGuest() {
      this.$store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "guest/uniqueid",
      })
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("appLoading", false);
            // this.$store.commit("menu", response.data.menu);
            localStorage.setItem("GuestID", response.data.guestId);
          } else {
            this.$store.commit("appLoading", false);
          }
        })
        .catch((err) => {
          this.$store.commit("appLoading", false);
          console.log(err);
        });
    },
    guestInfo() {
      this.$store.commit("appLoading", true);
      axios({
        method: "POST",
        url: "cartcount/guest",
        data: {
          guestId: localStorage.getItem("GuestID"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("appLoading", false);
            this.$store.commit("changeCart", response.data.cartcount);
          } else {
            this.$store.commit("appLoading", false);
          }
        })
        .catch((err) => {
          this.$store.commit("appLoading", false);
          console.log(err);
        });
    },

    getNotice() {
      this.appLoading = true;
      axios({
        url: "/announcement/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.announcement = response.data.announcement.announcement;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      // console.log("Top=", top);
      if (top > 2) {
        // console.log("scrolldedd", top);
        this.transAppBar = true;
      } else {
        // console.log("Else", top);
        this.transAppBar = false;
      }
    },
    getUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/me/product",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.userInfo = response.data;

            // this.$store.commit("loginUser", response.data.status);
            this.$store.commit("changeCart", response.data.cartcount);
            // this.$store.commit("changeWishlist", response.data.wishlistcount);
            this.$store.commit("userData", response.data.data);
            this.$store.commit("userType", response.data.data.role);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
